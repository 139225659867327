import ButtonBase from '@material-ui/core/ButtonBase';
import RoundSearchIcon from '@src_theme/icons/RoundSearch';
// import Wishlist from '@modules/customer/plugins/Wishlist';
// import { MAX_WIDTH } from '@core/theme/vars';
import helperCookies from '@helper_cookies';
import { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Typography from '@common_typography';
import dynamic from 'next/dynamic';
import Button from '@material-ui/core/Button';
import { useRouter } from 'next/router';
import { useTranslation } from '@i18n';

const OptionAutocomplete = dynamic(() => import('./Autocomplete/view'), { ssr: true });
const Autocomplete = dynamic(() => import('./Autocomplete'), { ssr: true });

// const shortenTitle = (text) => {
//     const length = 20;
//     return text.length > length ? `${text.substring(0, length)} ...` : text;
// };

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'white',
        marginBottom: '54px',
    },
    actionMenuRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > *': {
            flexGrow: 0,
            flexShrink: 0,
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
        },
    },
    logo: {
        width: 96,
    },
    shoppingAt: {
        flexBasis: 173,
    },
    searchBar: {
        flexGrow: 1,
        maxWidth: '400px',
        position: 'relative',
        '& > button': {
            position: 'absolute',
            top: 'calc(50% - 22px)',
            right: 8,
            zIndex: 999,
            [theme.breakpoints.down('xs')]: {
                width: '32px',
                height: '32px',
                top: '3px',
                right: '2px',
            },
        },
        '& .MuiTextField-root': {
            margin: 0,
        },
        '& .MuiInputBase-root': {
            fontSize: '28px',
            lineHeight: '20px',
            backgroundColor: '#F5F4F5',
            borderRadius: '25px !important',
            paddingTop: '5px !important',
            paddingBottom: '5px !important',
            paddingLeft: '20px !important',
        },
        '& fieldset': {
            border: 'none',
            padding: '30px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: '100%',
            padding: 0,
            height: '46px',
            marginTop: '10px',
        },
    },
    mainTitle: {
        maxWidth: '320px',
        fontWeight: 'bold',
        color: '#2E3092',
        fontSize: '31px',
        lineHeight: '41px',
        fontFamily: 'HartWell',
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
            lineHeight: '21px',
            maxWidth: '100%',
        },
        '@media (min-width: 1280px )': {
            maxWidth: '520px',
        },
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    btnBack: {
        padding: 0,
        marginLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
            minWidth: 'unset',
            width: '44px',
            height: '44px',
        },
    },
}));

const ViewTopNavigation = (props) => {
    const {
        searchByClick,
        handleSearch,
        setValue,
        value,
        // isLogin,
        title,
        // customer,
    } = props;

    const styles = useStyles();
    const router = useRouter();
    const { i18n } = useTranslation();
    const back = () => {
        router.back();
    };

    const showBackButton = router.pathname !== '/';

    const options = [
        { img: '/assets/img/flag_id.png', value: 'id' },
        { img: '/assets/img/flag_en.png', value: 'en' },
    ];
    const initialLanguage = helperCookies && helperCookies.get && helperCookies.get('language');
    const initialOption = options[initialLanguage ? options.findIndex((opt) => opt.value === initialLanguage) : 0];
    const [selectedOption] = React.useState(initialOption);

    useEffect(() => {
        i18n.changeLanguage(selectedOption.value);
        helperCookies.set('language', JSON.stringify(selectedOption.value));
    }, [selectedOption]);

    return (
        <div id="header" className={classNames(styles.root)}>
            <div className={styles.actionMenuRoot}>
                <div className={styles.titleWrapper}>
                    {
                        showBackButton ? (
                            <Button
                                onClick={back}
                                className={styles.btnBack}
                            >
                                <img
                                    src="/assets/img/back-icon.png"
                                    alt="back-icon"
                                    width={54}
                                    height={54}
                                />
                            </Button>
                        ) : null
                    }
                    <Typography className={styles.mainTitle} variant="h2">{title}</Typography>
                </div>
                <div className={styles.searchBar}>
                    <Autocomplete value={value} setValue={setValue} handleSearch={handleSearch} OptionsItem={OptionAutocomplete} />
                    <ButtonBase onClick={searchByClick}>
                        <RoundSearchIcon width={44} />
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

export default ViewTopNavigation;
