/* eslint-disable eqeqeq */
import Router from 'next/router';
import { removeIsLoginFlagging } from '@helper_auth';
import { removeCartId } from '@helper_cartid';
import Cookies from 'js-cookie';
import { useApolloClient } from '@apollo/client';
import { localTotalCart } from '@services/graphql/schema/local';
import {
    custDataNameCookie,
} from '@config';
import { setCookies } from '@helper_cookies';
import {
    getCustomer, removeToken,
    setSearchTerm as mutationSearchTerm, caCheckCustomerB2B,
} from '../../../services/graphql';

const CoreTopNavigation = (props) => {
    const {
        Content, storeConfig, t, isLogin, title,
    } = props;
    const [value, setValue] = React.useState('');
    const [deleteTokenGql] = removeToken();

    const [setSearchTerm] = mutationSearchTerm();

    let customerData = {};
    let customerLoading = false;
    let b2bLoading = false;
    if (isLogin && typeof window !== 'undefined') {
        const customer = getCustomer();
        customerLoading = customer.loading;
        if (customer.data) {
            customerData = customer.data;
        }
        const customerB2B = caCheckCustomerB2B();
        b2bLoading = customerB2B.loading;
        if (customerB2B.data) {
            Cookies.set('customerB2B', customerB2B.data.caCheckCustomerB2B, { sameSite: 'none', secure: true });
        }
    }
    const client = useApolloClient();

    const handleLogout = () => {
        deleteTokenGql().then(() => {
            Cookies.remove(custDataNameCookie, { sameSite: 'none', secure: true });
            Cookies.set('customerB2B', { status: false }, { sameSite: 'none', secure: true });
            setCookies('coupon', []);
            removeIsLoginFlagging();
            removeCartId();
            localStorage.removeItem('xdt_customer_id');
            client.writeQuery({ query: localTotalCart, data: { totalCart: 0 } });
            Router.push(
                Router.pathname == '/'
                    ? '/customer/account/login'
                    : '/',
            );
        }).catch(() => {
            //
        });
    };

    const handleSearch = (ev, isUseVirtual = false) => {
        if (isUseVirtual) {
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        } else if (ev.key === 'Enter' && ev.target.value !== '') {
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        }
    };

    const searchByClick = () => {
        if (value !== '') {
            setSearchTerm({
                variables: {
                    searchTerm: value,
                },
            }).then(() => {
                Router.push({
                    pathname: '/catalogsearch/result',
                    query: { q: value },
                });
            });
        }
    };

    return (
        <Content
            t={t}
            isLogin={isLogin}
            storeConfig={storeConfig}
            handleSearch={handleSearch}
            searchByClick={searchByClick}
            setValue={setValue}
            customer={customerData}
            handleLogout={handleLogout}
            value={value}
            customerLoading={customerLoading || b2bLoading}
            title={title}
        />
    );
};

export default CoreTopNavigation;
